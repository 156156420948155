import {API_URL, ControllerName} from "../utils/defaults";
import $baseHttp from "./base-http";
import $api from "./http";

export class ApiService {

  constructor() {}

  static widget = {
    getCompanies: async () => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `companies`))
    },
    getCompanyStaff: async (cid: number) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `companies/${cid}/staff`))
    },
    getStaffServices: async (cid: number, sid: number) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `companies/${cid}/staff/${sid}/services`))
    },
    getStaffDates: async (cid: number, sid: number, date: string) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `companies/${cid}/staff/${sid}/dates?date=${date}`))
    },
    getStaffDateTimes: async (cid: number, sid: number, date: string) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `companies/${cid}/staff/${sid}/times?date=${date}`))
    },
    createOrder: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.WIDGET, `order`), dto)
    },
    updateOrder: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.WIDGET, `order/${id}`), dto)
    },
    declineOrder: async (order_id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.WIDGET, `order/${order_id}`))
    },
    getLastOrder: async () => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `orders/last`))
    },
    getUserOrders: async () => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `orders?status=0`))
    },
    findClient: async (phone: string) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `client/${phone}`))
    },
    findBarber: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `barber/${id}`))
    },
    createTips: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.WIDGET, `order/tips`), dto)
    },
    createReview: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.WIDGET, `order/review`), dto)
    },

    getAllOrders: async (dto: any) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `admin/orders/list${this.buildParams(dto)}`))
    },
    getAllReviews: async (dto: any) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `admin/reviews/list${this.buildParams(dto)}`))
    },
    getAllTips: async (dto: any) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `admin/tips/list${this.buildParams(dto)}`))
    },

    getStats: async () => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `admin/stats`))
    },
    getChart: async (ds: string, de: string, bd: number) => {
      return await $api.get(this.buildUrl(ControllerName.WIDGET, `admin/chart?date_start=${ds}&date_end=${de}&by_day=${bd}`))
    }
  }

  static branches = {
    getAll: async () => {
      return await $api.get(this.buildUrl(ControllerName.BRANCHES, `get`))
    },
    getBranch: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.BRANCHES, `list/${id}`))
    },
    createCountry: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BRANCHES, `countries`), dto)
    },
    createRegion: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BRANCHES, `regions`), dto)
    },
    createBranch: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BRANCHES, `list`), dto)
    },
    updateCountry: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BRANCHES, `countries/${id}`), dto)
    },
    updateRegion: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BRANCHES, `regions/${id}`), dto)
    },
    updateBranch: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BRANCHES, `list/${id}`), dto)
    },
    deleteCountry: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BRANCHES, `countries/${id}`))
    },
    deleteRegion: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BRANCHES, `regions/${id}`))
    },
    deleteBranch: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BRANCHES, `list/${id}`))
    }
  }

  static blog = {
    getList: async () => {
      return await $api.get(this.buildUrl(ControllerName.BLOG, `list`))
    },
    createPost: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.BLOG, `list`), dto)
    },
    updatePost: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.BLOG, `list/${id}`), dto)
    },
    deletePost: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.BLOG, `list/${id}`))
    }
  }

  static events = {
    getHotspots: async () => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `hotspots`))
    },
    updateHotspot: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.EVENTS, `hotspots/${id}`), dto)
    },
    findVisiterByTicket: async (ticket_id: number) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `find_ticket/${ticket_id}`))
    },
    register: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.EVENTS, `register`), dto)
    },
    checkPayment: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `payment/${id}/check`))
    },
    getCounter: async () => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `counter`))
    },
    getStats: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `list/${id}/stats`))
    },
    getTicket: async (uid: number) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `ticket/${uid}`))
    },
    getEvents: async (query: {
      page: number,
      limit: number,
      search: string,
      type: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `list${this.buildParams(query)}`))
    },
    createEvent: async (dto: any) => {},
    updateEvent: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.EVENTS, `list/${id}`), dto)
    },
    deleteEvent: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.EVENTS, `list/${id}`))
    },
    getMembers: async (query: {
      event_id: number,
      page: number,
      limit: number,
      search: string,
      search_by_id?: string,
      order: string,
      orderBy: string,
      grouped: string,
      type: string,
      status: string
    }) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `members${this.buildParams(query)}`))
    },
    getUserDuples: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.EVENTS, `members/${dto.user_id}`), dto)
    },
    createAMember: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.EVENTS, `members_admin`), dto)
    },
    createMember: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.EVENTS, `members`), dto)
    },
    updateMember: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.EVENTS, `members/${id}`), dto)
    },
    deleteMember: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.EVENTS, `members/${id}`))
    },
    findTicket: async (data: any) => {
      return await $api.post(this.buildUrl(ControllerName.EVENTS, `find`), {data})
    },
    sendTicket: async (uid: number, eid: number) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `account/${uid}/ticket/${eid}`))
    },
    sendTicketSMS: async (uid: number, eid: number) => {
      return await $api.get(this.buildUrl(ControllerName.EVENTS, `account/${uid}/ticket_sms/${eid}`))
    }
  }

  static loyalty = {
    getWishesAll: async () => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `wishes_all`))
    },
    createBotMassMessage: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `telegram/mass_message`), dto)
    },
    createBotMassMessageTest: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `telegram/mass_message_test`), dto)
    },
    createOrderReview: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `orders/review`), dto)
    },
    updateOrderReview: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.LOYALTY, `orders/review/${id}`), dto)
    },
    deleteOrderReview: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `orders/review/${id}`))
    },
    getSubsList: async (query: {
      search: string, type: string, page: number, limit: number
    }) => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `admin/subs${this.buildParams(query)}`))
    },
    getBonusesList: async (query: {
      search: string, type: string, page: number, limit: number
    }) => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `admin/bonuses${this.buildParams(query)}`))
    },
    getNotifiesList: async (query: {
      user_id: number, page: number, limit: number
    }) => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `admin/notifies${this.buildParams(query)}`))
    },
    getClients: async (params: any) => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `clients${this.buildParams(params)}`))
    },
    getClientById: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `clients/${id}`))
    },
    getRefsById: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `clients_refs/${id}`))
    },
    adjustBalance: async (id: number, dto: {
      phone: string
      adjust: {
        amountDelta: number
        expirationPeriodDays: number
        comment: string
        notify: boolean
      }
    }) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `account/${id}/adjust`), dto)
    },
    checkAccount: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `auth/check_sign`), dto)
    },
    signInWithCode: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `auth/code`), dto)
    },
    signUpWithMaxma: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.LOYALTY, `auth/signup`), dto)
    },
    getAccount: async () => {
      return await $api.get(this.buildUrl(ControllerName.LOYALTY, `account`));
    },
    updateAdmAccount: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.LOYALTY, `account/${id}`), dto);
    },
    updateAccount: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.LOYALTY, `account`), dto);
    },
    deleteAccount: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `account/${id}`));
    },

    promos: {
      generateUniqueCode: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `promos/unique`))
      },
      getPromos: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `promos/list`))
      },
      getPromo: async (id: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `promos/list/${id}`))
      },
      createPromo: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `promos/list`), dto)
      },
      updatePromo: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `promos/list/${id}`), dto)
      },
      deletePromo: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `promos/list/${id}`))
      },

      getPromoCodes: async (pid: number, query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `promos/codes/${pid}${this.buildParams(query)}`))
      },
      createManyCodes: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `promos/codes`), dto)
      },
      createPromoCode: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `promos/code`), dto)
      },
      updatePromoCode: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `promos/code/${id}`), dto)
      },
      deletePromoCode: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `promos/code/${id}`))
      },
      activatePromoCode: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `promos/activate`), dto)
      }
    },

    adjusts: {
      create: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `adjusts`), dto)
      },
      update: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `adjusts/${id}`), dto)
      },
      remove: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `adjusts/${id}`))
      }
    },

    yclients: {
      getVisits: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `yclients/user_records${this.buildParams(query)}`));
      }
    },

    stats: {
      getBasicStats: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stats/basic`))
      },
      getBonusStats: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stats/bonus${this.buildParams(query)}`))
      },
      getBonusHistory: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stats/bonus/history${this.buildParams(query)}`))
      },
      getClientsStats: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stats/clients${this.buildParams(query)}`))
      },
      getCharts: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stats/charts${this.buildParams(query)}`))
      },
      getNotifyStats: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stats/notify`))
      }
    },

    notifies: {
      getPresendStat: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `notifies/presend`), dto)
      },
      getAllNotifies: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `notifies/list${this.buildParams(query)}`))
      },
      getAllMassNotifies: async (query: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `notifies/mass${this.buildParams(query)}`))
      },
      createNotifies: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `notifies`), dto)
      },
      deleteNotifies: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `notifies/delete`), dto)
      }
    },

    cats: {
      getCats: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `cats`))
      },
      getCategory: async (id: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `cats/${id}`))
      },
      createCategory: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `cats`), dto)
      },
      updateCategory: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `cats/${id}`), dto)
      },
      deleteCategory: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `cats/${id}`))
      },
    },

    stories: {
      getAll: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stories`))
      },
      lookStory: async (id: number) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `stories/look/${id}`), {})
      },
      createStory: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `stories`), dto)
      },
      updateStory: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `stories/${id}`), dto)
      },
      deleteStory: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `stories/${id}`))
      },
      resetCache: async () => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `stories/cache`))
      }
    },

    subscribes: {
      createSub: async (uid: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/subscribe`), dto)
      },
      createBotSub: async (uid: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/bot`), dto)
      },
      updateSub: async (uid: number, sid: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/subscribe/${sid}`), dto)
      },
      updateBotSub: async (uid: number, sid: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/bot/${sid}`), dto)
      },
      deleteSub: async (uid: number, sid: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/subscribe/${sid}`))
      },
      deleteBotSub: async (uid: number, sid: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/bot/${sid}`))
      },

      cancelSub: async (uid: number) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/subscribe/cancel`))
      },
      cancelBotSub: async (uid: number) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `account/${uid}/bot/cancel`))
      },

      createPayment: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `orders/payment/sub`), dto)
      }
    },

    store: {
      getProductAdds: async (pid: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/adds/${pid}`))
      },
      getProductVars: async (pid: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/vars/${pid}`))
      },
      createProductAdd: async (pid: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `store/adds/${pid}`), dto)
      },
      createProductVar: async (pid: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `store/vars/${pid}`), dto)
      },
      updateProductAdd: async (pid: number, id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `store/adds/${pid}/${id}`), dto)
      },
      updateProductVar: async (pid: number, id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `store/vars/${pid}/${id}`), dto)
      },
      deleteProductAdd: async (pid: number, id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `store/adds/${pid}/${id}`))
      },
      deleteProductVar: async (pid: number, id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `store/vars/${pid}/${id}`))
      },
      getListAdm: async (params: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/admin${this.buildParams(params)}`))
      },
      getList: async (params: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/list${this.buildParams(params)}`))
      },
      getStock: async (id: number, page: number, limit: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/list/stock/${id}${this.buildParams({page, limit})}`))
      },
      addToStock: async (pid: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `store/list/stock/${pid}/add`), dto)
      },
      writeFromStock: async (pid: number, dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `store/list/stock/${pid}/write`), dto)
      },
      getProductById: async (id: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/list/bi/${id}`))
      },
      getProductByName: async (linkname: string) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `store/list/bl/${linkname}`))
      },
      createProduct: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `store/list`), dto)
      },
      updateProduct: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `store/list/${id}`), dto)
      },
      deleteProduct: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `store/list/${id}`))
      }
    },

    orders: {
      getMy: async (params: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `orders${this.buildParams(params)}`))
      },
      getList: async (params: any) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `orders/list${this.buildParams(params)}`))
      },
      getOrderById: async (id: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `orders/list/${id}`))
      },
      createOrder: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `orders/list`), dto)
      },
      updateOrder: async (id: number, dto: any) => {
        return await $api.put(this.buildUrl(ControllerName.LOYALTY, `orders/list/${id}`), dto)
      },
      deleteOrder: async (id: number) => {
        return await $api.delete(this.buildUrl(ControllerName.LOYALTY, `orders/list/${id}`))
      },
      createPayment: async (dto: any) => {
        return await $api.post(this.buildUrl(ControllerName.LOYALTY, `orders/payment`), dto)
      },
      checkPayment: async (id: number) => {
        return await $api.get(this.buildUrl(ControllerName.LOYALTY, `orders/payment/status/${id}`))
      }
    }
  }

  static users = {
    signIn: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signin`), dto);
    },
    signUp: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `signup`), dto);
    },
    getAccount: async () => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `account`));
    },
    updateAccount: async (dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.USERS, `account`), dto);
    },
    getUsers: async (params: any) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `list${this.buildParams(params)}`));
    },
    getAllUsers: async () => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `list/all`));
    },
    getLostedUsers: async () => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `list/losted`));
    },
    findTicket: async (data: string) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `find`), {data});
    },
    getTicket: async (id: number) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `ticket/${id}`));
    },
    updateUser: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.USERS, `account/${id}`), dto);
    },
    deleteUser: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.USERS, `account/${id}`));
    },
    sendTicket: async (id: number) => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `account/${id}/ticket`));
    },

    getJournal: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `journal${this.buildParams(query)}`))
    },
    getUserJournal: async (user_id: number, query: any) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `journal/${user_id}${this.buildParams(query)}`))
    }
  }

  static core = {
    getVKVideos: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `vk/videos`))
    },
    getSettings: async () => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `settings`));
    },
    updateSetting: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CORE, `settings/${id}`), dto);
    },
    sendMail: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CORE, `sendmail`), dto)
    },
    getSuggestions: async (query: any) => {
      return await $api.get(this.buildUrl(ControllerName.CORE, `suggestions${this.buildParams(query)}`));
    }
  }

  static crm = {
    getTables: async () => {
      return await $api.get(this.buildUrl(ControllerName.CRM, `tables`))
    },
    createTable: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CRM, `tables`), dto)
    },
    updateTable: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CRM, `tables/${id}`), dto)
    },
    deleteTable: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.CRM, `tables/${id}`))
    },
    getTable: async (id: number, page: number, limit: number, search: string) => {
      return await $api.get(this.buildUrl(ControllerName.CRM, `tables/${id}${this.buildParams({page, limit, search})}`))
    },
    createColumn: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CRM, `cols`), dto)
    },
    updateColumn: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CRM, `cols/${id}`), dto)
    },
    deleteColumn: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.CRM, `cols/${id}`))
    },
    createTicket: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.CRM, `tickets`), dto)
    },
    updateTicket: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.CRM, `tickets/${id}`), dto)
    },
    deleteTicket: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.CRM, `tickets/${id}`))
    }
  }

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }
}